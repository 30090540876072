import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import LynPadernBgImage from "../components/LynPadernBGImage"

export default function book() {
  return (
    <Layout>
      <LynPadernBgImage style={{}}>
        <div className="hero-image-canvas"> </div>
      </LynPadernBgImage>

      <div className="buttons-box">
        <div className="button-text">
          <Link to="/new-clients">
            <div className="book-button">
              <h2>New</h2>
            </div>
          </Link>
        </div>

        <div className="button-text">
          <Link to="/existing-clients">
            <div className="book-button">
              <h2>Existing</h2>
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
